export const recommendedReadsData = {
  howItWorks: {
    id: 'how-it-works',
    img: {
      src: '/next-assets/brand-pages/heros/desktop/how-it-works.webp',
      alt: 'Freedom Debt Relief',
    },
    title: 'How it works',
    subtitle: 'How Freedom Debt Relief works',
    link: '/how-it-works',
  },
  whoWeHelp: {
    id: 'who-we-help-reads',
    img: {
      src: '/next-assets/brand-pages/heros/desktop/who-we-help.webp',
      alt: 'Freedom Debt Relief client parents with their children',
    },
    title: 'Who we help',
    subtitle: 'The clients and hardships we help with',
    link: '/who-we-help/',
  },
  whyWereBetter: {
    id: 'why-our-program',
    img: {
      src: '/next-assets/brand-pages/heros/desktop/why-were-better.webp',
      alt: 'female Freedom Debt Relief client',
    },
    title: 'Why our program',
    subtitle: 'Why choose Freedom',
    link: '/why-were-better',
  },
  testimonials: {
    id: 'client-stories',
    img: {
      src: '/next-assets/brand-pages/heros/desktop/testimonials.webp',
      alt: 'young male Freedom Debt Relief client',
    },
    title: 'Client stories',
    subtitle: 'What our clients say about us',
    link: '/testimonials',
  },
  faq: {
    id: 'faqs-reads',
    img: {
      src: '/next-assets/brand-pages/heros/desktop/faq.webp',
      alt: 'smiling Freedom Debt Relief customer service male representative',
    },
    title: 'FAQs',
    subtitle: 'Frequently Asked Questions',
    link: '/faq/',
  },
}

export const featuredInData = [
  {
    index: 1,
    name: 'motley',
    logo: '/next-assets/brand-pages/why-freedom/motley.webp',
    link: 'https://www.fool.com/the-ascent/research/waiving-credit-card-interest-saves-average-american-88-month/',
    alt: 'The Motley Fool',
  },
  {
    index: 2,
    name: 'cnbs',
    logo: '/next-assets/brand-pages/why-freedom/cnbc.webp',
    link: 'https://www.cnbc.com/2019/08/02/69percent-of-homeowners-wished-they-saved-more-for-downpayment.html',
    alt: 'CNBC',
  },
  {
    index: 3,
    name: 'yahoo',
    logo: '/next-assets/brand-pages/why-freedom/yahoo.webp',
    link: 'https://finance.yahoo.com/news/coronavirus-pandemic-household-income-survey-shows-155245815.html',
    alt: 'Yahoo Finance',
  },
  {
    index: 4,
    name: 'usatoday',
    logo: '/next-assets/brand-pages/why-freedom/usatoday.webp',
    link: 'https://www.usatoday.com/story/money/2020/02/12/credit-card-debt-average-balance-hits-6-200-and-limit-31-000/4722897002/',
    alt: 'USA today',
  },
  {
    index: 5,
    name: 'msn',
    logo: '/next-assets/brand-pages/why-freedom/msn.webp',
    link: 'https://www.msn.com/en-us/money/personalfinance/6-first-steps-post-grads-need-to-take-to-pay-off-student-debt/ar-AAYdJyN?li=BBnbfcL',
    alt: 'MSN',
  },
]
